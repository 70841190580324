import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import ReactPlayer from "react-player";

import styled, { keyframes } from "styled-components";

import slugify from "slugify";
import distances from "src/assets/styles/distances.js";
import colors from "src/assets/styles/colors.js";
import main from "src/assets/styles/main.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import Line from "src/components/global/Line.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";
import Body from "src/components/global/Typo/Body.js";
import ButtonArrow from "src/components/global/ButtonArrow.js";

const WrapperSliderObj = styled.div`
  position: relative;
  margin-bottom: ${distances.gap}rem;
  overflow: hidden;
  border-radius: ${main.radius}rem;
  height: 40vw;
`;
const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.hover {
    transform: scale(1.05);
  }
`;
const StyledVideo = styled.video`
  width: 100%;
  /* height: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

const ProjectInfoWrapper = styled.div`
  position: absolute;
  bottom: 20rem;
  left: 20rem;
  width: calc(100% - 80rem);
  background-color: var(--rest);
  padding: 20rem;
  border-radius: ${main.radius}rem;
  overflow: hidden;
  ${Body} {
    color: var(--bg);
  }
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  .button {
    margin-top: 40rem;
    position: absolute;
    span {
      transition-delay: 0.2s;
    }
  }
  &.hover {
    padding-bottom: 80rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 15rem;
    left: 15rem;
    bottom: 15rem;
    width: calc(100% - 60rem);
    ${Body} {
      font-size: ${typographySizes.ss}rem;
    }
  }
`;
const ProjectTitle = styled.h4`
  font-size: ${typographySizes.sm}rem;
  color: var(--bg);

  width: calc(100% - 20rem);
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background-color: red; */
`;
const ProjectGrid = ({
  title,
  workUrl,
  category,
  videoSrc,
  imgSrc,
  coming_soon,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <WrapperSliderObj
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {imgSrc !== null ? (
        <StyledImage
          className={hover && "hover"}
          loading="eager"
          fluid={imgSrc}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="tex"
        />
      ) : (
        <StyledVideo autoPlay muted loop>
          <source src={videoSrc} type="video/mp4" />
        </StyledVideo>
      )}
      <ProjectInfoWrapper className={hover && coming_soon !== true && "hover"}>
        <Body>{category}</Body>

        <ProjectTitle>{title}</ProjectTitle>
        <ButtonArrow className="button" dark hover={hover}>
          zobacz więcej
        </ButtonArrow>
      </ProjectInfoWrapper>
      {coming_soon !== true && <StyledLink to={workUrl}></StyledLink>}
    </WrapperSliderObj>
  );
};

export default ProjectGrid;
