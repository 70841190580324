import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import { Scrollbar } from "swiper";
import { gsap } from "gsap";
import slugify from "slugify";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import "swiper/css";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import "swiper/css/scrollbar";
import distances from "src/assets/styles/distances.js";
import main from "src/assets/styles/main.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";
import Img from "gatsby-image";
import Body from "src/components/global/Typo/Body.js";
import Button from "src/components/global/Button.js";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";
import ArrowControl from "src/assets/svg/arrow-drag.inline.svg";

const Wrapper = styled.div`
  margin-top: ${distances.sectionMarginBig}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;
  z-index: 104;
  position: relative;
  background-color: var(--bg);
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const Title = styled(SmallTitle)`
  margin-bottom: 50rem;
  width: 43.75%;
  position: relative;
  span,
  div {
    font-size: inherit;
    color: var(--rest);
    display: inline-block;
    /* opacity: 0; */
  }
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 20rem;
    width: 80%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;
const SwiperWrapper = styled.div`
  position: relative;
`;

const SliderWrapper = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  .swiper {
    width: calc((100vw) * 0.75);
    height: 43vw;

    @media (max-width: ${mediaQuery.desktop}) {
      height: 53vw;
    }
    @media (max-width: ${mediaQuery.laptop}) {
      height: 63vw;
      width: 100vw;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      height: 109vw;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    height: 40vw;

    overflow: hidden;
    border-radius: ${main.radius}rem;
    position: relative;
    /* background: #fff; */
    margin-right: ${distances.pageMarginBig}rem;
    a {
      z-index: 100;
    }

    @media (max-width: ${mediaQuery.desktop}) {
      height: 48vw;
    }
    @media (max-width: ${mediaQuery.laptop}) {
      height: 58vw;
      :first-of-type {
        margin-left: ${distances.pageMarginBig}rem;
      }
    }
    @media (max-width: ${mediaQuery.tablet}) {
      height: 100vw;
      :first-of-type {
        margin-left: ${distances.pageMarginSmall}rem;
      }
    }
  }
  .swiper-scrollbar {
    background-color: var(--rest);
    width: calc(100% - ${distances.pageMarginBig}rem - 85rem) !important;
    left: 85rem;
    height: 0.5rem !important;
    bottom: 7rem;
    cursor: pointer;
    @media (max-width: ${mediaQuery.laptop}) {
      width: calc(100% - ${distances.pageMarginBig * 2}rem - 85rem) !important;
      margin-left: ${distances.pageMarginBig}rem;
    }
    @media (max-width: ${mediaQuery.tablet}) {
      width: calc(
        100% - ${distances.pageMarginSmall * 2}rem - 85rem
      ) !important;
      margin-left: ${distances.pageMarginSmall}rem;
    }
  }
  .swiper-scrollbar-drag {
    height: 3rem !important;

    background-color: var(--rest);
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    flex-wrap: wrap;
  }
`;

const Left = styled.div`
  width: calc((100vw - ${distances.pageMarginBig * 2}rem) * 0.25);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-left: ${distances.pageMarginBig}rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 80%;
    margin-bottom: 50rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: ${distances.pageMarginSmall}rem;
  }
`;

const StyledBody = styled(Body)`
  width: calc(100% - ${distances.gap}rem);
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 50rem;
  }
`;

const ButtonWrapper = styled.div``;

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const StyledVideo = styled.video`
  width: 100%;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

const ControlWrapper = styled.div`
  display: flex;
  /* background-color: red; */
  height: 14rem;
  width: 85rem;
  position: absolute;
  left: calc((100vw) * 0.25);
  left: 0;
  /* margin-left: 30rem; */
  bottom: 0;
  z-index: 100;
  @media (max-width: ${mediaQuery.laptop}) {
    left: ${distances.pageMarginBig}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    left: ${distances.pageMarginSmall}rem;
  }
`;

const RightArrow = styled(ArrowControl)`
  width: 13rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  #arrow {
    fill: var(--rest);
  }
  &:hover {
    transform: translateY(-3rem);
  }
`;

const LeftArrow = styled(ArrowControl)`
  width: 13rem;
  transform: rotate(180deg);
  margin-right: 20rem;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  #arrow {
    fill: var(--rest);
  }
  &:hover {
    transform: translateY(-3rem) rotate(180deg);
  }
`;

const Controls = () => {
  const swiper = useSwiper();

  return (
    <ControlWrapper>
      <LeftArrow onClick={() => swiper.slidePrev()} />
      <RightArrow onClick={() => swiper.slideNext()} />
    </ControlWrapper>
  );
};

const Cursor = styled.div`
  position: fixed;
  z-index: 1;
  top: 75rem;
  left: 75rem;
  height: 0rem;
  width: 0rem;
  border-radius: 150rem;
  background-color: var(--rest);
  cursor: none;
  /* left: ${(props) => props.x}rem; */
  /* top: 50%; */
  z-index: 100;

  transition: all 0s;
  pointer-events: none;
  transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1),
    width 0.3s cubic-bezier(0.65, 0, 0.35, 1),
    top 0.3s cubic-bezier(0.65, 0, 0.35, 1),
    left 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  &.onHover {
    top: 0rem;
    left: 0rem;
    height: 150rem;
    width: 150rem;
    transition: height 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      width 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      top 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      left 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    /* background-color: blue; */
  }
  &.onDrag {
    height: 120rem;
    width: 120rem;
    top: 15rem;
    left: 15rem;
    transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1),
      width 0.3s cubic-bezier(0.65, 0, 0.35, 1),
      top 0.3s cubic-bezier(0.65, 0, 0.35, 1),
      left 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const CursorIn = styled(Cursor)`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: none;
`;

const CursorInWrapper = styled.div`
  display: flex;

  align-items: center;
`;

const CursorTextWrapper = styled.span`
  position: relative;
  /* bottom: 2rem; */
  margin: 0 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 30rem;
  line-height: 30rem;

  span {
    color: var(--bg);
    font-size: ${typographySizes.s}rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
  }
  &.hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const CursrRightArrow = styled(Arrow)`
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  #arrow {
    fill: var(--bg);
  }
  &.onDrag {
    transform: translateX(-10rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const CursrLeftArrow = styled(Arrow)`
  transform: rotate(180deg);
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);

  #arrow {
    fill: var(--bg);
  }
  &.onDrag {
    transform: rotate(180deg) translateX(-10rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const About = ({ title, infoText, slider, button, lang, pageName }) => {
  const [onSlider, setOnSlider] = useState(false);
  const [showDrag, setShowDrag] = useState(false);
  const [onDragSlider, setOnDragSlider] = useState(false);
  const [mousePosX, setMousePosX] = useState();
  const [mousePosY, setMousePosY] = useState();
  const [scrollTop, setScrollTop] = useState(0);

  const boxRef = useRef();
  const boxRefIn = useRef();
  const swiperWrapper = useRef();
  const langUrl = lang === "pl" ? "" : `/${lang}`;
  const aboutUrl = `${langUrl}/${slugify(pageName, {
    lower: true,
    strict: true,
  })}`;

  const movecursor = (e) => {
    var boxfrom = swiperWrapper.current.getBoundingClientRect().top;
    if (e.clientY - boxfrom < 500 && onSlider) {
      setShowDrag(true);
    } else {
      setShowDrag(false);
    }
    if (onSlider) {
      gsap.to(boxRef.current, {
        duration: 0,
        x: e.clientX - 75,
        y: e.clientY - 75,
      });
      gsap.to(boxRefIn.current, {
        duration: 0.1,
        x: e.clientX - 75,
        y: e.clientY - 75,
      });
    }
  };

  const onEnterFn = () => {
    setOnSlider(true);
  };

  const onLeaveFn = () => {
    setOnSlider(false);
  };

  const setMousePos = (clientX, clientY) => {
    setMousePosX(clientX);
    setMousePosY(clientY);
  };
  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser) {
      window.onscroll = function (e) {
        setScrollTop(document.body.getBoundingClientRect().top);

        if (
          mousePosX > swiperWrapper.current.getBoundingClientRect().left &&
          mousePosX < swiperWrapper.current.getBoundingClientRect().right &&
          mousePosY > swiperWrapper.current.getBoundingClientRect().top &&
          mousePosY < swiperWrapper.current.getBoundingClientRect().bottom
        ) {
          setShowDrag(true);
        } else {
          setShowDrag(false);
        }
      };
    }
  }, [scrollTop, mousePosX, mousePosY, isBrowser]);

  return (
    <Wrapper onPointerMove={movecursor}>
      <MainWrapper>
        <Title>{title}</Title>
      </MainWrapper>
      <SliderWrapper>
        <Left>
          <StyledBody>{infoText}</StyledBody>

          <ButtonWrapper>
            <Button to={aboutUrl}>{button}</Button>
          </ButtonWrapper>
        </Left>
        <SwiperWrapper
          ref={swiperWrapper}
          onMouseEnter={onEnterFn}
          onMouseLeave={onLeaveFn}
          onMouseMove={({ clientX, clientY }) => setMousePos(clientX, clientY)}
        >
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              0: {
                slidesPerView: 1.5,
                spaceBetween: 15,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 3.4,
                spaceBetween: distances.gap,
              },
            }}
            // spaceBetween={distances.gap}
            modules={[Scrollbar]}
            scrollbar={{ draggable: true }}
            className="mySwiper"
            grabCursor={true}
            onTouchStart={() => {
              setOnDragSlider(true);
            }}
            onTouchEnd={() => {
              setOnDragSlider(false);
            }}
          >
            <Cursor
              ref={boxRef}
              className={`${showDrag && "onHover"}  ${
                onDragSlider && showDrag && "onDrag"
              }`}
            />
            <CursorIn
              ref={boxRefIn}
              className={`${showDrag && "onHover"}  ${
                onDragSlider && showDrag && "onDrag"
              }`}
            >
              <CursorInWrapper>
                <CursrLeftArrow
                  className={onDragSlider && showDrag && "onDrag"}
                />
                <CursorTextWrapper className={showDrag && "hover"}>
                  <span>drag</span>
                  <span>drag</span>
                </CursorTextWrapper>
                <CursrRightArrow
                  className={onDragSlider && showDrag && "onDrag"}
                />
              </CursorInWrapper>
            </CursorIn>

            <Controls onMouseEnter={onLeaveFn} />

            {slider.map((slidePos) => (
              <SwiperSlide>
                {" "}
                {slidePos.localFile.childImageSharp !== null ? (
                  <StyledImage
                    loading="eager"
                    fluid={slidePos.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="tex"
                  />
                ) : (
                  <StyledVideo autoPlay muted loop>
                    <source
                      src={slidePos.localFile.publicURL}
                      type="video/mp4"
                    />
                  </StyledVideo>
                )}
              </SwiperSlide>
            ))}
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </SwiperWrapper>
      </SliderWrapper>
    </Wrapper>
  );
};

export default About;
