import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";

import mediaQuery from "src/assets/styles/mediaQuery";

const MidTitle = styled.h2`
  font-size: ${typographySizes.xl}rem;
  color: var(--rest);
  line-height: 1;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}rem;
  }
`;

export default MidTitle;
