import React, { useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import { Engine, Render, Bodies, World, MouseConstraint } from "matter-js";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";

import mediaQuery from "src/assets/styles/mediaQuery.js";

import MidTitle from "src/components/global/Typo/MidTitle.js";
import Line from "src/components/global/Line.js";
import MainWrapper from "src/components/global/MainWrapper.js";

const StyledMainWrapper = styled(MainWrapper)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  /* border: solid 1rem var(--bg); */
  position: relative;
  & > div {
    margin: 0 auto;
    width: calc(100%);
  }
  @media (max-width: ${mediaQuery.laptop}) {
    pointer-events: none;
    & > div {
      width: 100%;
    }
  }
`;

const Title = styled(MidTitle)`
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: 10vw;
  }
`;

const StyledLine = styled(Line)`
  margin-top: 10rem;
`;

// let onece = true;

const WhatWeDo = ({ imgs, title }) => {
  const scene = useRef();
  const isPressed = useRef(false);
  const engine = useRef(Engine.create());
  let canvH = 320;
  let blockSizeDivider = 2;
  let blockTextureDivider = 0.5;
  const myRef = useRef();
  const { inViewport, enterCount, leaveCount } = useInViewport(myRef);
  const [hasRun, setHasRun] = useState(false);

  //preload images
  const imgUrls = imgs.map((img) => img.localFile.publicURL);

  const isBrowser = typeof window !== "undefined";
  // if (isBrowser) {
  //   if (document.body.clientWidth > 768) {
  //     cw = document.body.clientWidth * 1;
  //   } else {
  //     cw = document.body.clientWidth;
  //   }
  // }
  useEffect(() => {
    let cw = document.body.clientWidth - distances.pageMarginBig * 2;
    let ch = document.body.clientHeight;
    if (isBrowser) {
      if (document.body.clientWidth > 768) {
        cw = document.body.clientWidth * 1;
      } else {
        cw = document.body.clientWidth - 30;
        canvH = 420;
      }
    }
    let ratio = 1;
    if (isBrowser) {
      ratio = window.devicePixelRatio;
    }
    const render = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: cw,
        height: canvH,
        wireframes: false,
        background: "transparent",

        pixelRatio: ratio,
      },
    });
    const mouseControl = MouseConstraint.create(engine.current, {
      element: scene.current,
      constraint: {
        render: {
          visible: false,
        },
      },
    });
    mouseControl.mouse.element.removeEventListener(
      "mousewheel",
      mouseControl.mouse.mousewheel
    );
    mouseControl.mouse.element.removeEventListener(
      "DOMMouseScroll",
      mouseControl.mouse.mousewheel
    );
    World.add(engine.current.world, [
      Bodies.rectangle(-1000, canvH / 2, 2000, canvH, {
        isStatic: true,
        render: {
          fillStyle: colors.dark,
        },
      }),
      Bodies.rectangle(cw / 2, canvH + 1000, cw, 2000, {
        isStatic: true,
        render: {
          fillStyle: colors.dark,
        },
      }),
      Bodies.rectangle(cw + 1000, canvH / 2, 2000, canvH, {
        isStatic: true,
        render: {
          fillStyle: colors.dark,
        },
      }),
      mouseControl,
    ]);

    Engine.run(engine.current);
    Render.run(render);

    return () => {
      Render.stop(render);
      World.clear(engine.current.world);
      Engine.clear(engine.current);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    };
  }, []);

  const handleDown = () => {
    isPressed.current = true;
  };

  const handleUp = () => {
    isPressed.current = false;
  };

  var interval = 300;
  let cw;
  if (isBrowser) {
    if (document.body.clientWidth > 768) {
      cw = document.body.clientWidth - distances.pageMarginBig * 2;
      blockSizeDivider = 2;
      blockTextureDivider = 0.5;
    } else {
      cw = document.body.clientWidth;
      blockSizeDivider = 3;
      blockTextureDivider = 0.33;
    }
  }

  if (enterCount === 1 && !hasRun) {
    imgs.forEach((element, index) => {
      setTimeout(function () {
        let xPos = getRandomArbitrary(150, cw - 150);
        if (xPos > cw - element.width / blockSizeDivider - 100) {
          xPos = cw - element.width / blockSizeDivider - 100;
        }

        const yPos = -120;

        const pos = Bodies.rectangle(
          xPos,
          yPos,
          element.width / blockSizeDivider,
          element.height / blockSizeDivider,
          {
            mass: 8,
            restitution: 0.3,
            friction: 0.5,
            angle: 0.1,
            render: {
              sprite: {
                xScale: blockTextureDivider,
                yScale: blockTextureDivider,
                texture: element.localFile.publicURL,
              },
            },
          }
        );
        World.add(engine.current.world, [pos]);
      }, index * interval);
    });
    setTimeout(function () {
      World.add(engine.current.world, [
        Bodies.rectangle(cw / 2, -1000, cw, 2000, {
          isStatic: true,
          render: {
            fillStyle: colors.dark,
          },
        }),
      ]);
      if (isBrowser) {
        window.addEventListener("scroll", function (e) {
          const speed = checkScrollSpeed();
          engine.current.world.gravity.y = -speed / 30;
          setTimeout(function () {
            engine.current.world.gravity.y = 1;
          }, 10);
        });
      }
    }, imgs.length * interval + 500);

    setHasRun(true);
  }

  var checkScrollSpeed = (function (settings) {
    settings = settings || {};

    var lastPos,
      newPos,
      timer,
      delta,
      delay = settings.delay || 50; // in "ms" (higher means lower fidelity )

    function clear() {
      lastPos = null;
      delta = 0;
    }

    clear();

    return function () {
      newPos = window.scrollY;
      if (lastPos != null) {
        // && newPos < maxScroll
        delta = newPos - lastPos;
      }
      lastPos = newPos;
      clearTimeout(timer);
      timer = setTimeout(clear, delay);
      return delta;
    };
  })();

  engine.current.world.gravity.y = 1;
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
  return (
    <StyledMainWrapper>
      <Helmet>
        {imgUrls.map((img, index) => (
          <link key={index} rel="preload" as="image" href={img} />
        ))}
      </Helmet>
      <Line />

      <Wrapper
        onMouseDown={handleDown}
        onMouseUp={handleUp}
        ref={myRef}
        // onMouseMove={handleAddCircle}
      >
        <Title>{title}</Title>
        <div ref={scene} />
      </Wrapper>
      <StyledLine />
    </StyledMainWrapper>
  );
};
export default WhatWeDo;
