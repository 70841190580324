import React from "react";
const { useState } = React;

const Sketch = React.lazy(() => import("react-p5"));

function SketchF({ vidData }) {
  let vid;
  let xoff = 0;
  var moveleft = 3;

  var worksColect = [];
  const [newBg, setNewBg] = useState();

  function vidLoad(p5) {
    vid.autoplay();
  }
  function preload(p5) {
    vid = p5.createVideo(vidData, vidLoad);
    vid.autoplay();
    vid.volume(0);
    vid.position(-4000, -4000);
    vid.loop();
  }
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
  };

  const draw = (p5) => {
    p5.clear();

    if ((p5.frameCount > 100) & (p5.frameCount < 102)) {
      vid.play();
    }
    if (p5.mouseX > 0) {
      var b = new works(p5.mouseX, p5.mouseY, p5, xoff);
    } else {
      var b = new works(p5.windowWidth / 2, p5.windowHeight / 2, p5, xoff);
    }

    worksColect.push(b);
    //
    for (var i = 0; i < worksColect.length; i++) {
      worksColect[i].show();

      //not
      worksColect[i].move();
      //

      if (worksColect.length > 80) {
        worksColect.splice(0, 1);
      }
    }

    xoff += 0.005;
  };

  function works(x, y, p5) {
    let someNoise = p5.map(p5.noise(xoff), 0, 1, -100, 100);

    this.x = x;
    this.y = y + someNoise;

    this.show = function () {
      p5.image(vid, this.x - 461 / 2, this.y - 260 / 2, 461, 260);
    };

    this.move = function () {
      this.x -= moveleft;
    };
  }

  const isSSR = typeof window === "undefined";
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Sketch setup={setup} draw={draw} preload={preload} />;
        </React.Suspense>
      )}
    </>
  );
}

export default SketchF;
