import React from "react";
import slugify from "slugify";

import styled from "styled-components";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

import MidTitle from "src/components/global/Typo/MidTitle.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Body from "src/components/global/Typo/Body.js";
import Project from "src/components/global/Projects/Project.js";
import Button from "src/components/global/Button.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import ProjectGrid from "src/components/global/Projects/ProjectGrid.js";

const Wrapper = styled.div`
  margin-top: ${distances.sectionMarginBig}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;
  position: relative;

  width: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-wrap: wrap;
  }
`;

const Title = styled(MidTitle)`
  width: 43.75%;
  @media (max-width: ${mediaQuery.desktopBig}) {
    width: 56.25%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  span,
  div {
    font-size: inherit;
    color: var(--rest);
    display: inline-block;
    /* opacity: 0; */
  }
`;

const StyledBody = styled(Body)`
  width: 31.25%;
  margin-bottom: 20rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 50%;
    margin-top: 20rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 0;
  }
  span,
  div {
    font-size: inherit;
    color: var(--rest);
    display: inline-block;
  }
`;

const ProjectsWrapper = styled.div`
  margin-top: ${distances.sectionMarginBig / 2}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 50rem;
  }
`;

const BottomRow = styled.div`
  margin-left: 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 100rem;
  }
`;

const ButtonWrapper = styled.a`
  border: solid 1rem;
  border-color: var(--rest);
  font-size: ${typographySizes.m}rem;
  text-align: center;
  color: var(--rest);
  align-self: center;
  display: inline-block;
  padding: 0 30rem;

  cursor: pointer;
  /* margin-left: 50rem; */
  padding: 0 30rem;

  border-radius: 60rem;
  height: 51rem;
  overflow: hidden;
  text-decoration: none;
  span {
    font-size: inherit;
    color: inherit;
    display: block;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    text-decoration: none;
  }
  :hover {
    span {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }

  &.active {
    background: var(--rest);
    color: var(--bg);
    border: solid 1rem var(--bg);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    padding: 5rem 15rem;

    height: 18rem;
  }
`;

const Projects = ({
  infoText,
  title,
  projects,
  buttonText,
  allButtonText,
  lang,
  worksPageName,
}) => {
  const langUrl = lang === "pl" ? "" : `/${lang}`;
  const worksUrl = worksPageName
    ? `${langUrl}/${slugify(worksPageName, {
        lower: true,
        strict: true,
      })}`
    : "";
  return (
    <Wrapper>
      <MainWrapper>
        <TopRow>
          <Title>{title}</Title>
          <StyledBody>{infoText}</StyledBody>
        </TopRow>
      </MainWrapper>

      <ProjectsWrapper>
        {projects.map((project) => (
          <Project
            title={project.node.Title}
            thumbnails={project.node.Thumbnails}
            introText={project.node.Intro_text}
            categories={project.node.categories}
            buttonText={buttonText}
            lang={lang}
          />
        ))}
      </ProjectsWrapper>
      <BottomRow>
        {/* <Button to={worksUrl}>{allButtonText} </Button> */}
        <ButtonWrapper href={worksUrl}>
          <span>{allButtonText}</span>
          <span>{allButtonText}</span>
        </ButtonWrapper>
      </BottomRow>
    </Wrapper>
  );
};
export default Projects;
