import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import ReactPlayer from "react-player";

import styled, { keyframes } from "styled-components";

import slugify from "slugify";
import distances from "src/assets/styles/distances.js";
import colors from "src/assets/styles/colors.js";
import main from "src/assets/styles/main.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import Line from "src/components/global/Line.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import SmallTitle from "src/components/global/Typo/SmallTitle.js";
import Body from "src/components/global/Typo/Body.js";
import ButtonArrow from "src/components/global/ButtonArrow.js";

const StyledMainWrapper = styled(MainWrapper)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50rem;
  }
`;

const StyledSmallTitle = styled(SmallTitle)`
  margin-right: 60rem;

  @media (max-width: ${mediaQuery.desktopBig}) {
    font-size: ${typographySizes.m}rem;
    line-height: ${typographySizes.mh};
    margin-right: 20rem;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    font-size: ${typographySizes.sm}rem;
    line-height: ${typographySizes.smh};
  }
`;

const ProjectWrapper = styled(Link)`
  color: var(--rest);
  background-color: var(--bg);
  cursor: pointer;
  display: block;
  position: relative;
  width: calc(100%);
  z-index: 0;
  text-decoration: none;

  .first {
    display: none;
  }

  :first-of-type {
    .first {
      display: block;
    }
  }
`;

const InnerWrapper = styled.div`
  padding: ${distances.gap}rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const InfoCol = styled.div`
  width: 43.75%;

  display: flex;
  flex-direction: column;

  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-top: 20rem;
  }
`;

const StyledBody = styled(Body)`
  margin-top: 10rem;
  width: 80%;
  @media (max-width: ${mediaQuery.desktopBig}) {
    width: calc(100% - 20rem);
    margin-right: 20rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-right: 0;
  }
`;

const ImgsCol = styled.div`
  display: flex;
  width: 56.25%;
  height: 25vw;
  justify-content: space-between;
  video {
    object-fit: cover;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    height: 60vw;
  }
`;

const SmallImg = styled.div`
  width: calc(38.88% - ${distances.gap / 2}rem);
  height: 100%;

  border-radius: ${main.radius}rem;
  overflow: hidden;
  position: relative;
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const BigImg = styled.div`
  width: calc(61.12% - ${distances.gap / 2}rem);
  height: 100%;

  border-radius: ${main.radius}rem;
  overflow: hidden;
  position: relative;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const Single = styled.div`
  width: 100%;
  height: 100%;

  border-radius: ${main.radius}rem;
  overflow: hidden;
  position: relative;
`;

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const StyledVideo = styled.video`
  height: 100%;
  min-width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  object-fit: cover;
`;

const CategoriesWrapper = styled.div`
  width: 80%;
  margin-top: ${distances.gap}rem;
  margin-bottom: ${distances.gap}rem;
  ${Body} {
    padding: 15rem 0;
    text-transform: lowercase;
  }
  @media (max-width: ${mediaQuery.desktopBig}) {
    width: calc(100% - 20rem);
    margin-right: 20rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-right: 0;
    ${Body} {
      padding: 20rem 0;
      text-transform: lowercase;
    }
  }
`;

const moveFromTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const moveToTop = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;

const moveFromBottom = keyframes`
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const moveToBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
`;

const Mask = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  position: absolute;
  height: calc(100% + 1rem);
  width: 100%;
  background-color: var(--rest);
  display: flex;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  top: -1rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    padding: 0;
  }
  @media (min-width: ${mediaQuery.tablet}) {
    &.top {
      animation: ${moveFromTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
    &.bottom {
      animation: ${moveFromBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
    &.toBottom {
      animation: ${moveToBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
    &.toTop {
      animation: ${moveToTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
  }

  ${StyledSmallTitle}, ${StyledBody}, ${Body} {
    color: var(--bg);
  }
  ${InnerWrapper} {
    /* padding-bottom: 0; */
  }

  ${InnerWrapper} {
    height: calc(100% - 40rem);
  }
`;

const Project = ({
  title,
  thumbnails,
  introText,
  categories,
  buttonText,
  lang,
}) => {
  const [direction, setDirection] = useState("");
  const [boxHover, setBoxHover] = useState(false);
  const [animationEnded, setAnimationEnded] = useState(false);
  const wrapper = useRef(null);

  const relativeCoords = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const h = bounds.height;
    const y = e.clientY - bounds.top;
    setBoxHover(true);
    if (y > h / 2) {
      setDirection("bottom");
    } else {
      setDirection("top");
    }

    setAnimationEnded(
      setTimeout(() => {
        return true;
      }, 310)
    );
  };

  const relativeCoordsLeave = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const h = bounds.height;
    const y = e.clientY - bounds.top;
    setBoxHover(false);
    const intervalId = setInterval(() => {
      if (animationEnded) {
        if (y > h / 2) {
          setDirection("toBottom");
        } else {
          setDirection("toTop");
        }
        clearInterval(intervalId);
      }
    }, 10);
  };
  let slugTitle = slugify(title, {
    lower: true,
    strict: true,
  });
  const urlLang = lang === "pl" ? "/projekty/" : `/${lang}/works/`;
  const workUrl = urlLang + slugTitle;

  const isBrowser = typeof window !== "undefined";
  let desktop = true;
  if (isBrowser) {
    if (isBrowser && window.innerWidth < 768) {
      desktop = false;
    }
  }
  return (
    <ProjectWrapper
      onMouseEnter={relativeCoords}
      onMouseLeave={relativeCoordsLeave}
      to={workUrl}
    >
      <MainWrapper>
        <Line className="first" />
      </MainWrapper>
      {desktop && (
        <Mask className={direction}>
          <MainWrapper>
            <InnerWrapper ref={wrapper}>
              <InfoCol>
                <StyledSmallTitle>{title}</StyledSmallTitle>
                <StyledBody>{introText}</StyledBody>
                <CategoriesWrapper>
                  <Line color={colors.dark} />

                  <Body>
                    {categories.map(
                      (category, index) =>
                        `${category.Name}  ${
                          index == categories.length - 1 ? "" : "|"
                        } `
                    )}
                  </Body>

                  <Line color={colors.dark} />
                </CategoriesWrapper>
                <ButtonArrow dark hover={boxHover}>
                  {buttonText}
                </ButtonArrow>
              </InfoCol>
              <ImgsCol>
                {thumbnails[0].Small_left && (
                  <>
                    <SmallImg>
                      {" "}
                      {thumbnails[0].Small_left_2.localFile.childImageSharp !==
                      null ? (
                        <StyledImage
                          loading="eager"
                          fluid={
                            thumbnails[0].Small_left_2.localFile.childImageSharp
                              .fluid
                          }
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt="tex"
                        />
                      ) : (
                        <StyledVideo autoPlay muted loop playsInline>
                          <source
                            src={thumbnails[0].Small_left_2.localFile.publicURL}
                            type="video/mp4"
                          />
                        </StyledVideo>
                      )}
                    </SmallImg>
                    <BigImg>
                      {" "}
                      {thumbnails[0].Big_right_2.localFile.childImageSharp !==
                      null ? (
                        <StyledImage
                          loading="eager"
                          fluid={
                            thumbnails[0].Big_right_2.localFile.childImageSharp
                              .fluid
                          }
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt="tex"
                        />
                      ) : (
                        <StyledVideo autoPlay muted loop playsInline>
                          <source
                            src={thumbnails[0].Big_right_2.localFile.publicURL}
                            type="video/mp4"
                          />
                        </StyledVideo>
                      )}
                    </BigImg>
                  </>
                )}
                {thumbnails[0].Small_right && (
                  <>
                    <BigImg>
                      {" "}
                      {thumbnails[0].Big_left_2.localFile.childImageSharp !==
                      null ? (
                        <StyledImage
                          loading="eager"
                          fluid={
                            thumbnails[0].Big_left_2.localFile.childImageSharp
                              .fluid
                          }
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt="tex"
                        />
                      ) : (
                        <StyledVideo autoPlay muted loop playsInline>
                          <source
                            src={thumbnails[0].Big_left_2.localFile.publicURL}
                            type="video/mp4"
                          />
                        </StyledVideo>
                      )}
                    </BigImg>
                    <SmallImg>
                      {" "}
                      {thumbnails[0].Small_right_2.localFile.childImageSharp !==
                      null ? (
                        <StyledImage
                          loading="eager"
                          fluid={
                            thumbnails[0].Small_right_2.localFile
                              .childImageSharp.fluid
                          }
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt="tex"
                        />
                      ) : (
                        <StyledVideo autoPlay muted loop playsInline>
                          <source
                            src={
                              thumbnails[0].Small_right_2.localFile.publicURL
                            }
                            type="video/mp4"
                          />
                        </StyledVideo>
                      )}
                    </SmallImg>
                  </>
                )}
                {thumbnails[0].Single && (
                  <Single>
                    {thumbnails[0].Single_2.localFile.childImageSharp !==
                    null ? (
                      <StyledImage
                        loading="eager"
                        fluid={
                          thumbnails[0].Single_2.localFile.childImageSharp.fluid
                        }
                        objectFit="cover"
                        objectPosition="50% 50%"
                        alt="tex"
                      />
                    ) : (
                      // <StyledVideo autoPlay muted loop playsInline>
                      //   <source
                      //     src={thumbnails[0].Single_2.localFile.publicURL}
                      //     type="video/mp4"
                      //   />
                      // </StyledVideo>
                      <ReactPlayer
                        controls={false}
                        playing={true}
                        url={thumbnails[0].Single_2.localFile.publicURL}
                        width="100%"
                        height="100%"
                        muted={true}
                        playsInline={true}
                        loop={true}
                      />
                    )}
                  </Single>
                )}
              </ImgsCol>
            </InnerWrapper>
          </MainWrapper>
        </Mask>
      )}

      <StyledMainWrapper>
        <InnerWrapper>
          <InfoCol>
            <StyledSmallTitle>{title}</StyledSmallTitle>
            <StyledBody>{introText}</StyledBody>
            <CategoriesWrapper>
              <Line />

              <Body>
                {categories.map(
                  (category, index) =>
                    `${category.Name}  ${
                      index == categories.length - 1 ? "" : "|"
                    } `
                )}
              </Body>

              <Line />
            </CategoriesWrapper>
            <ButtonArrow hover={boxHover}> {buttonText}</ButtonArrow>
          </InfoCol>
          <ImgsCol>
            {thumbnails[0].Small_left && (
              <>
                <SmallImg>
                  {" "}
                  {thumbnails[0].Small_left.localFile.childImageSharp !==
                  null ? (
                    <StyledImage
                      loading="eager"
                      fluid={
                        thumbnails[0].Small_left.localFile.childImageSharp.fluid
                      }
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt="tex"
                    />
                  ) : (
                    <StyledVideo autoPlay muted loop playsInline>
                      <source
                        src={thumbnails[0].Small_left.localFile.publicURL}
                        type="video/mp4"
                      />
                    </StyledVideo>
                  )}
                </SmallImg>
                <BigImg>
                  {" "}
                  {thumbnails[0].Big_right.localFile.childImageSharp !==
                  null ? (
                    <StyledImage
                      loading="eager"
                      fluid={
                        thumbnails[0].Big_right.localFile.childImageSharp.fluid
                      }
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt="tex"
                    />
                  ) : (
                    <StyledVideo autoPlay muted loop playsInline>
                      <source
                        src={thumbnails[0].Big_right.localFile.publicURL}
                        type="video/mp4"
                      />
                    </StyledVideo>
                  )}
                </BigImg>
              </>
            )}
            {thumbnails[0].Small_right && (
              <>
                <BigImg>
                  {" "}
                  {thumbnails[0].Big_left.localFile.childImageSharp !== null ? (
                    <StyledImage
                      loading="eager"
                      fluid={
                        thumbnails[0].Big_left.localFile.childImageSharp.fluid
                      }
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt="tex"
                    />
                  ) : (
                    <StyledVideo autoPlay muted loop playsInline>
                      <source
                        src={thumbnails[0].Big_left.localFile.publicURL}
                        type="video/mp4"
                      />
                    </StyledVideo>
                  )}
                </BigImg>
                <SmallImg>
                  {" "}
                  {thumbnails[0].Small_right.localFile.childImageSharp !==
                  null ? (
                    <StyledImage
                      loading="eager"
                      fluid={
                        thumbnails[0].Small_right.localFile.childImageSharp
                          .fluid
                      }
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt="tex"
                    />
                  ) : (
                    <StyledVideo autoPlay muted loop playsInline>
                      <source
                        src={thumbnails[0].Small_right.localFile.publicURL}
                        type="video/mp4"
                      />
                    </StyledVideo>
                  )}
                </SmallImg>
              </>
            )}
            {thumbnails[0].Single && (
              <Single>
                {thumbnails[0].Single.localFile.childImageSharp !== null ? (
                  <StyledImage
                    loading="eager"
                    fluid={thumbnails[0].Single.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="tex"
                  />
                ) : (
                  <StyledVideo autoPlay muted loop playsInline>
                    <source
                      src={thumbnails[0].Single.localFile.publicURL}
                      type="video/mp4"
                    />
                  </StyledVideo>
                )}
              </Single>
            )}
          </ImgsCol>
        </InnerWrapper>

        <Line />
      </StyledMainWrapper>
    </ProjectWrapper>
  );
};

export default Project;
